<template>
  <div class="page">
    <HeaderNav></HeaderNav>
    <router-view/>
    <BottomNav></BottomNav>
    <div @click="toTop" v-show="ShowNav"
         class="top-gif">
      <img src="./assets/toTop.gif" alt="图片丢了嘿~">
    </div>
  </div>
</template>
<script>
import HeaderNav from '@/components/nav/HeaderNav'
import BottomNav from '@/components/nav/BottomNav'

export default {
  name: 'App',
  components: {
    HeaderNav,
    BottomNav
  },
  computed: {
    ShowNav: {
      get () {
        return this.$store.state.ShowNav
      },
      set (val) {
        this.$store.commit('updateNavState', val)
      }
    }
  },
  methods: {
    handleScroll () {
      // const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // console.log(scrollTop)
      if (scrollTop <= 280) {
        this.ShowNav = false
      } else {
        this.ShowNav = true
      }
    },
    toTop () {
      // scrollIt(0, 1500, linear, this.currentPageYOffset);
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss">
.page {
  min-width: 1920px;
}

div {
  white-space: pre-line;
}

.top-gif {
  width: 100px;
  height: 100px;
  position: fixed;
  right: 20px;
  bottom: 5%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

// 滚动条
::-webkit-scrollbar {
  width: 8px;
  height: 1px;
}

/*滑块部分*/
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgb(29, 143, 225);
}

/*轨道部分*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
  border-radius: 5px;
}
</style>
