export const zh = {
  homeTab: '首页',
  selectionTab: 'U+功能',
  exploreTab: '探索U+',
  exploreTab_property: '达智物业 APP',
  exploreTab_owner: '达智业主 APP',
  exploreTab_merchant: '达智商家 APP',

  downloadTab: '下载',
  agreementTab: 'U+服务协议',
  agreementTab2: '隐私政策',
  language: '简体中文',
  downloadTitle: '立即下载',
  IOSDownload: 'IOS 下载',
  AndroidDownload: 'Android 下载',
  ontList: {
    title: '联系我们',
    oneItem: '电话',
    twoItem: '邮箱',
  },
  twoList: {
    title: '探索 XFS',
    twoItem: '白皮书',
    threeItem: '职能合约',
    fourItem: '分布式存储',
  },
  threeList: {
    title: '资源',
    oneItem: 'XFS 技术文档',
    twoItem: '开发者资源',
  },
  fourList: {
    title: '社交',
  },
  aboutUs: {
    gywm: '关于我们',
    hlht: '互联互通 , 造福万家',
    introduce:
      '达智物业系统, 是湛云(广东)物联科技有限公司旗下的系统产品, 旨在房地产开发到前期物业服务到业主服务(业主物业的保质增值, 商家零距离服务等)\n        到政府主管部门监管的各相关环节。 以此形成相互促进的闭环, 使多方公赢',
    more: '查看更多',
  },
  trait: {
    wmdtd: {
      wmd: '我们的',
      td: ' 特点',
      yyes: '应运而生：时代与读者的双重选择',
    },
    body: {
      title: '达智物业系统 | 产品特点',
      content:
        '通过我们的平台，使物业、业主、商家、地产和政府更紧密地互相依存，有效的粘连在一起，粘性更大、更紧密。',
      list: {
        mxwy: {
          title: '面向物业',
          content:
            '通过简单实用有效的培训资料、OA 管理、采购等内容共享，减轻物业公司管理成本、提高工作效率、增强抵抗风险的能力。',
        },
        mxyz: {
          title: '面向业主',
          content:
            '通过简洁高效的服务板块、特色的停车场管理、居家养老、项目全体业主统一论坛群等内容，让业主、物业、商家三方公开透明 高效的活动，生活零障碍。',
        },
        mxsj: {
          title: '面向商家',
          content:
            '通过社区团购、商品详情展示、商家信息 展示等内容，无需任何广告，即可让商家 零距离接触业主、直面业主，减少运营成本，提高运营效率。',
        },
        mxdc: {
          title: '面向地产',
          content:
            '将开发商、施工方、物业管理、业主整合 在一个承接查验系统中，清晰、详细地将查验、返修、结果等流程无纸化，使开发商、施工方、物业、业主无缝对接。增加项目交楼的质量及收楼率，提高项目运营效率和业主满意度。',
        },
        mxzf: {
          title: '面向政府',
          content:
            '通过系统数据的统筹整合、搭建四级监管服务平台模式（业主端、业委会端、企业端、监管端），可视化图像处理、政策公开信息、办事窗口等内容，实现物业管理企业、政府部门、居民信息共享，赋能政府管理，实现社区美好生活。',
        },
      },
    },
  },
  future: {
    wmdfz: {
      title: '我们的发展',
      content: '应运而生：时代与读者的双重选择',
    },
    left: {
      title: '经济效益',
      xtgg: {
        title: '系统广告',
        content:
          '首页5条滚动广告、次页各1条推荐广告，另有启动页广告、跳窗广告、特定推荐广告等形式广告数条。',
      },
      gwpx: {
        title: '岗位培训',
        content:
          '提供岗位视频培训，按年收费；可支持企业定制化服务，配套指定培训教学。',
      },
      zfgm: {
        title: '政府购买',
        content: '社区商铺平台加盟费。',
      },
      sqsp: {
        title: '社区商铺',
        content: '社区商铺平台加盟费。',
      },
      jzfw: {
        title: '家政服务',
        content: '可入股一家家政公司，享受平台流量红利、精准化推广......',
      },
      sqyl: {
        title: '社区养老',
        content:
          '可入股一家社区养老机构，提供定制化功能以及其他配套增值服务，享受利润分红。',
      },
      wyjm: {
        title: '物业加盟',
        content: '物业公司加盟维护费。',
      },
    },
    right: {
      pd: {
        icon: '铺垫',
        title: '开发阶段',
        content:
          '2022年4月30日，全系统开发完毕；\n2022年发展1-3家物业公司使用；\n2023年1月1日达到可经营状态。',
      },
      yl: {
        icon: '盈利',
        title: '发育阶段',
        content: '产品研发、销售、维护三线稳定；\n实现10家以上物业公司使用。',
      },
      gs: {
        icon: '高速',
        title: '乘势阶段',
        time: '2031—未来',
        content:
          '产品研发、销售、维护等能力、竞争力稳步提高，逐步向环北部湾服务。',
      },
      ph: {
        icon: '平衡',
        title: '销售阶段',
        content:
          '全年实现5-8家物业公司正式使用；\n公司对外设计业务逐步展开；\n实现销售正常化；\n实现收支平衡。',
      },
      qw: {
        icon: '千万',
        title: '积势阶段',
        content: '产品根据市场再优化，争取在2030年达到年收益过千万。',
      },
    },
  },
  functionSelection: [
    {
      name: '智慧物业系统',
      module: [
        {
          name: '系统登录',
          childModule: [
            {
              name: '注册登录',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '修改密码',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '子账号管理',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
          ]
        },
        {
          name: '积分系统',
          childModule: [
            {
              name: '积分商城',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '签到有礼',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
          ]
        },
        {
          name: '物业服务',
          childModule: [
            {
              name: '一键呼叫管家',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '访客管理',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: '实现该功能需要购买安装扫码门禁机；使用该门禁机每年需支付云服务费用'
            },
            {
              name: '联系物业',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '报事报修',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '装修备案',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '拉货放行',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '投诉建议',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '公众服务',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '投票事项',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
          ]
        },
        {
          name: '智慧生活',
          childModule: [
            {
              name: '便民服务',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '社区团购',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '家政服务',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '折扣店',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '美食娱乐',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '百货购物',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '健康管理',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '社区养老',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '智慧监控',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '手机开门',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '充电桩',
              kind: '定制（硬/软对接类）',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '论坛活动',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '社区商家',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
          ]
        },
        {
          name: '系统基础功能',
          childModule: [
            {
              name: '个人资料',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '搜索',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '扫一扫',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '消息提醒',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '意见反馈',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '系统更新',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            }
          ]
        },
        {
          name: '社区商务系统',
          childModule: [
            {
              name: '商品管理、订单管理',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '经营数据',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '客户信息',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '财务对账',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '装修备案',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            }
          ]
        },
        {
          name: '物业工作系统',
          childModule: [
            {
              name: '员工工卡、内部通知、公司概况、公司制度、通讯录、相关调查、会议纪要、员工风采、通知通告',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '考勤打卡',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '绩效考核',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '工作汇总',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '图形化报表分析',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '公司文化墙',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '工单管理',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '工资条',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '工单窗台（报事报修、投诉建议、呈示报批）',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '设施设备（设备系统、设备类别、设备台帐、巡检设置、维保设置、巡检任务、维保任务、设备报表）',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '仓库管理（仓储设置、仓库管理、物品管理、供应商管理、采购管理、集采管理、入库管理、出库管理、盘点管理、仓储参数、物料报表）',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '品质管理（核查设置、核查标准、核查规则、核查任务、整改任务、核查报表）',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '安保巡逻（地点管理、路线管理、巡逻任务、巡逻报表）',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
          ]
        },
        {
          name: '培训系统',
          childModule: [
            {
              name: '物业培训共享版',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '物业培训高级定制版',
              kind: '选配',
              money: '免费',
              flag: 1,
              mark: ''
            }
          ]
        },
        {
          name: '财务管理',
          childModule: [
            {
              name: '财务收付管理（财务设置、收费标准设置、财务参数设置、财务打印模板、基础物业收费功能：收费中心、付费中心、抄表管理、优惠政策设置、收付费报表）',
              kind: '定制（个性化内容类）',
              money: '10000元/永久',
              flag: 0,
              mark: ''
            },
            {
              name: '催交管理（催交费管理，客户提醒、员工提醒、硬件提醒）',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '交款管理（收款交账管理、交款报表）',
              kind: '增配',
              money: '免费',
              flag: 1,
              mark: ''
            },
          ]
        },
        {
          name: '收楼系统',
          childModule: [
            {
              name: '业主返修、收楼统计、工单统计、工单管理、工单流转、工单提醒、工单查询、工单处理、超时工单、工单打印模板、工单报表导出',
              kind: '选配',
              money: '10000元/永久',
              flag: 0,
              mark: ''
            }
          ]
        },
        {
          name: '承接查验',
          childModule: [
            {
              name: '项目管理、故障类型设置、承建商设置、户型图设置、工单打印模板、工单报表导出、工单统计、工单管理、工单流转、工单提醒、工单查询、工单处理、超时工单、地产管理中心、施工管理中心',
              kind: '选配',
              money: '20000元/永久',
              flag: 0,
              mark: ''
            }
          ]
        },
        {
          name: '党建引领',
          childModule: [
            {
              name: '【定制版】（信息管理、公告管理、支部建设、红色小区、红色活动、红色共享、志愿服务、学习强国）',
              kind: '选配',
              money: '3000元/永久',
              flag: 0,
              mark: ''
            }
          ]
        },
        {
          name: '政府联结',
          childModule: [
            {
              name: '政府通知公告、政府发文、政府投票、政府监管',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            }
          ]
        },
        {
          name: '客户自定义功能',
          childModule: [
            {
              name: '客户自定义功能',
              kind: '定制 （自定义功能类）',
              money: '5000~/项（需估价）',
              flag: 0,
              mark: ''
            }
          ]
        },
      ]
    },
    {
      name: '系统运行基础云服务器',
      module: [
        {
          name: '系统（含数据库）将部署在云端服务器。提供系统运行要求最低配置的服务器（5.0版本标准架构4GB数据库/2核4G2Gbps30万PPS服务器费用）。 系统（含数据库）将部署在云端服务器。提供系统运行所要求得最低配置的服务器（5.0版本标准架构4GB数据库/2核4G2Gbps30万PPS服务器费用）。',
          childModule: [
            {
              name: '系统（含数据库）将部署在云端服务器。提供系统运行要求最低配置的服务器（5.0版本标准架构4GB数据库/2核4G2Gbps30万PPS服务器费用）。 系统（含数据库）将部署在云端服务器。提供系统运行所要求得最低配置的服务器（5.0版本标准架构4GB数据库/2核4G2Gbps30万PPS服务器费用）。',
              kind: '标配',
              money: '1500/年',
              flag: 0,
              mark: ''
            }
          ]
        },
      ]
    },
    {
      name: '系统维护',
      module: [
        {
          name: '系统故障排查、病毒攻击等各类内容维护',
          childModule: [
            {
              name: '系统故障排查、病毒攻击等各类内容维护',
              kind: '标配',
              money: '1500/年',
              flag: 0,
              mark: ''
            }
          ]
        },
      ]
    },
    {
      name: '智慧物业APP（业主端）',
      module: [
        {
          name: 'IOS端应用APP和Android端应用APP',
          childModule: [
            {
              name: 'IOS端应用APP和Android端应用APP',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            }
          ]
        },
      ]
    },
    {
      name: '智慧物业APP（商家端）',
      module: [
        {
          name: 'IOS端应用APP和Android端应用APP',
          childModule: [
            {
              name: 'IOS端应用APP和Android端应用APP',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            }
          ]
        },
      ]
    },
    {
      name: '智慧物业APP （物业端）',
      module: [
        {
          name: 'IOS端应用APP和Android端应用APP',
          childModule: [
            {
              name: 'IOS端应用APP和Android端应用APP',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            }
          ]
        },
      ]
    },
    {
      name: '智慧 物业管理后台',
      module: [
        {
          name: '智慧物业管理后台（网页版）',
          childModule: [
            {
              name: '信息发布（内部通知、社区通知、公司概况、公司制度、员工风采）',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '项目数据管理（业户信息、商家信息、楼栋信息、企业信息）',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '人力资源管理（组织架构、级别管理、员工信息）',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '工单管理（承接查验、报事报修、投诉建议、拉货放行）',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '社区管理（来访客人、业主投票、论坛活动、联系物业）',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '账户设置（含权限管理）',
              kind: '标配',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '住宅动态',
              kind: '定制 （个性化内容类）',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '商铺动态',
              kind: '定制 （个性化内容类）',
              money: '免费',
              flag: 1,
              mark: ''
            },
            {
              name: '自定义学习培训资料管理',
              kind: '定制 （个性化内容类）',
              money: '2000起（需估价）',
              flag: 0,
              mark: '年费为存储和使用这些资料所需的服务器租用费用；具体价格与资料数量、类型、使用频率有关。'
            },
            {
              name: '车位情况',
              kind: '定制 （硬/软对接类）',
              money: '5000起（需估价）',
              flag: 0,
              mark: ''
            },
            {
              name: '车位情况',
              kind: '定制 （硬/软对接类）',
              money: '5000起（需估价）',
              flag: 0,
              mark: ''
            },
            {
              name: '在岗情况',
              kind: '定制 （硬/软对接类）',
              money: '5000起（需估价）',
              flag: 0,
              mark: ''
            },
            {
              name: '财务管理',
              kind: '定制 （硬/软对接类）',
              money: '5000起（需估价）',
              flag: 0,
              mark: ''
            },
            {
              name: '车辆管理',
              kind: '定制 （硬/软对接类）',
              money: '5000起（需估价）',
              flag: 0,
              mark: ''
            },
            {
              name: '客户自定义功能',
              kind: '定制 （自定义功能类）',
              money: '5000起（需估价）',
              flag: 0,
              mark: ''
            },
          ]
        },
      ]
    },
    {
      name: '可视化大屏',
      module: [
        {
          name: '项目BI大屏（软件）',
          childModule: [
            {
              name: '项目信息；投诉统计；访客信息；日历；天气；时钟；楼盘布局图；',
              kind: '选配',
              money: '30000元/永久',
              flag: 0,
              mark: ' 1.使用该功能需自行配置可以接入互联网的计算机和显示大屏硬件设备； 2.该价格仅为提供软件系统（显示页面）的价格；每项功能的定制包含该功能与智慧物业管理后台和智慧物业系统的数据联动。'
            },
            {
              name: '收费统计',
              kind: '定制 （硬/软对接类）',
              money: '3000起（需估价）',
              flag: 0,
              mark: ' 1.使用该功能需自行配置可以接入互联网的计算机和显示大屏硬件设备； 2.该价格仅为提供软件系统（显示页面）的价格；每项功能的定制包含该功能与智慧物业管理后台和智慧物业系统的数据联动。'
            },
            {
              name: '成本统计',
              kind: '定制 （硬/软对接类）',
              money: '3000起（需估价）',
              flag: 0,
              mark: ' 1.使用该功能需自行配置可以接入互联网的计算机和显示大屏硬件设备； 2.该价格仅为提供软件系统（显示页面）的价格；每项功能的定制包含该功能与智慧物业管理后台和智慧物业系统的数据联动。'
            },
            {
              name: '设备信息',
              kind: '定制 （硬/软对接类）',
              money: '3000起（需估价）',
              flag: 0,
              mark: ' 1.使用该功能需自行配置可以接入互联网的计算机和显示大屏硬件设备； 2.该价格仅为提供软件系统（显示页面）的价格；每项功能的定制包含该功能与智慧物业管理后台和智慧物业系统的数据联动。'
            },
            {
              name: '警报时间管理',
              kind: '定制 （硬/软对接类）',
              money: '3000起（需估价）',
              flag: 0,
              mark: ' 1.使用该功能需自行配置可以接入互联网的计算机和显示大屏硬件设备； 2.该价格仅为提供软件系统（显示页面）的价格；每项功能的定制包含该功能与智慧物业管理后台和智慧物业系统的数据联动。'
            },
            {
              name: '监控对接',
              kind: '定制 （硬/软对接类）',
              money: '3000起（需估价）',
              flag: 0,
              mark: ' 1.使用该功能需自行配置可以接入互联网的计算机和显示大屏硬件设备； 2.该价格仅为提供软件系统（显示页面）的价格；每项功能的定制包含该功能与智慧物业管理后台和智慧物业系统的数据联动。'
            },
            {
              name: '水电耗能统计',
              kind: '定制 （硬/软对接类）',
              money: '3000起（需估价）',
              flag: 0,
              mark: ' 1.使用该功能需自行配置可以接入互联网的计算机和显示大屏硬件设备； 2.该价格仅为提供软件系统（显示页面）的价格；每项功能的定制包含该功能与智慧物业管理后台和智慧物业系统的数据联动。'
            },
            {
              name: '经营面板',
              kind: '定制 （硬/软对接类）',
              money: '3000起（需估价）',
              flag: 0,
              mark: ' 1.使用该功能需自行配置可以接入互联网的计算机和显示大屏硬件设备； 2.该价格仅为提供软件系统（显示页面）的价格；每项功能的定制包含该功能与智慧物业管理后台和智慧物业系统的数据联动。'
            },
            {
              name: '人员通行统计',
              kind: '定制 （硬/软对接类）',
              money: '3000起（需估价）',
              flag: 0,
              mark: ' 1.使用该功能需自行配置可以接入互联网的计算机和显示大屏硬件设备； 2.该价格仅为提供软件系统（显示页面）的价格；每项功能的定制包含该功能与智慧物业管理后台和智慧物业系统的数据联动。'
            },
            {
              name: '客户自定义功能',
              kind: '定制 （自定义功能类）',
              money: '5000起（需估价）',
              flag: 0,
              mark: ' 1.使用该功能需自行配置可以接入互联网的计算机和显示大屏硬件设备； 2.该价格仅为提供软件系统（显示页面）的价格；每项功能的定制包含该功能与智慧物业管理后台和智慧物业系统的数据联动。'
            },
          ]
        },
      ]
    },
    {
      name: '增值服务',
      module: [
        {
          name: '智慧物业系统+智慧物业管理后台PC端软件化（windows版）',
          childModule: [
            {
              name: '智慧物业系统+智慧物业管理后台PC端软件化（windows版）',
              kind: '定制',
              money: '50000元',
              flag: 0,
              mark: ''
            }
          ]
        },
        {
          name: '智慧物业系统+智慧物业管理后台PC端软件化（OS X版）	',
          childModule: [
            {
              name: '智慧物业系统+智慧物业管理后台PC端软件化（OS X版）	',
              kind: '定制',
              money: '50000元',
              flag: 0,
              mark: ''
            }
          ]
        },
        {
          name: 'APP端微信小程序化',
          childModule: [
            {
              name: '公告通知、投诉举报、报修报事、呼叫物业、问卷调查、投票表决、小区动态、小区活动、费用查缴、个人中心、费用催交（仅限以上功能）',
              kind: '定制',
              money: '300起/年',
              flag: 0,
              mark: '年费为：使用小程序需每年向腾讯支付300元服务费；另线上支付会产生交易手续费，微信为6‰'
            }
          ]
        },
        {
          name: '本地数据服务器系统架设',
          childModule: [
            {
              name: '本地数据服务器系统架设',
              kind: '选配',
              money: '免费',
              flag: 1,
              mark: '不含服务器硬件。'
            }
          ]
        },
        {
          name: '专属定制云服务器系统架设',
          childModule: [
            {
              name: '专属定制云服务器系统架设',
              kind: '选配',
              money: '3000起（需估价）',
              flag: 0,
              mark: '年费为：租用服务器所需年服务费；根据服务器内存、CPU、带宽、硬盘容量不同而价格不同。'
            }
          ]
        },
        {
          name: '系统升级',
          childModule: [
            {
              name: '系统（含系统、APP、管理后台）的升级',
              kind: '选配',
              money: '免费',
              flag: 1,
              mark: ''
            }
          ]
        },
        {
          name: '公众号服务',
          childModule: [
            {
              name: '客户管理、实名认证、通知管理、投票管理、问卷调查、活动管理、动态管理、素材管理、认证统计、移动端认证、生日祝福',
              kind: '定制',
              money: '5000起（需估价）',
              flag: 0,
              mark: '微信公众号后台，可自定义公众号前台界面'
            }
          ]
        },
        
      ]
    },
    {
      name: '说明',
      module: [
        {name: '1.银  卡：加盟物业管理面积低于50万㎡高于10万㎡（包括一次性签约和累计签约）含10万㎡。',
        },{name: '2.关于标配:是U+智慧物业系统原生自带的功能。初次签约时，标准功能随系统自带，并包含1次基础UI定制（含物业/小区LOGO、配色、基本布局的定制）和1次适配定制（功能适配客户自身工作流程/制度）。',
        },{name: '3.关于增配：指U+智慧物业系统未来通过更新迭代系统版本所实现的标准功能。',
        },{name: '4.关于定制：',
        },{name: '（1）个性化内容定制类(二次开发)：该类定制功能指顾客在U+智慧系统已开发功能基础上，根据自身特点进行定制扩展开发的需求',
        },{name: '（2）硬/件对接定制类：这些定制功能，都是需要对接相关硬件或软件系统才能实现的。受限于部分型号硬件/软件自身设计缺陷，可能无法提供对应功能定制。各个功能的定制，要根据客户已安装的硬件设备/软件系统来确定价格。暂未安装相关硬件设备/软件系统的，我们可以提供硬件/软件方案。使用我们提供方案的硬件，则功能定制费用较低。',
        },{name: '（3）自定义功能定制类（一次开发）：针对客户自定义的新功能需求，我们提出如何实现该功能的定制开发方案，在客户认可后，进行相关功能的开发。',
        },{name: '5.关于选配：指U+系统中需要客户付费选配才能享受的高级版功能。',
        }
      ]
    },
  ]
};
