export const en = {
  homeTab: 'home',
	
  exploreTab: 'Explore U+',
	exploreTab_property: 'sheng he property APP',
	exploreTab_owner: 'sheng he owner APP',
	exploreTab_merchant: 'sheng he merchant APP',
	
	
  downloadTab: 'Download',
  agreementTab:'Service Agreement U+',
  agreementTab2:'Privacy Policy',
  language: 'English',
  downloadTitle: 'Download',
  IOSDownload: 'Download IOS',
  AndroidDownload: 'Download Android',
  trait: 'Characteristic',
  ontList: {
    title: 'Contact Us',
    oneItem: 'Telephone',
    twoItem: 'E-Mail',
  },
  twoList: {
    title: 'Explore XFS',
    twoItem: 'White Paper',
    threeItem: 'Functional Contract',
    fourItem: 'Distributed Storage',
  },
  threeList: {
    title: 'Resources',
    oneItem: 'XFS Technical Documentation',
    twoItem: 'Developer Resources',
  },
  fourList: {
    title: 'Social Contact',
  },
  aboutUs: {
    gywm: 'About Us',
    hlht: 'Interconnection , Benefit thousands of families',
    introduce:
      'U+ smart property system is a system product of Zhanyun (Guangdong) IOT Technology Co., Ltd., which aims at all relevant links from real estate development to early-stage property services to owner services (quality and value-added of owner properties, zero distance service of merchants, etc.) to supervision of competent       government departments. So as to form a closed loop of mutual promotion and make multiple parties win',
    more: 'See more',
  },
  trait: {
    wmdtd: {
      wmd: 'Ours',
      td: ' Future',
      yyes: 'Came into being: the dual choice of the times and readers',
    },
    body: {
      title: 'U+ smart property system | product features',
      content:
        'Through our platform, property, owners, businesses, real estate and the government are more closely interdependent and effectively bonded together, with greater and closer stickiness.',
      list: {
        mxwy: {
          title: 'Property oriented',
          content:
            'Through simple, practical and effective training materials, OA management, procurement and other content sharing, reduce the management cost of the property company, improve work efficiency and enhance the ability to resist risks.',
        },
        mxyz: {
          title: 'Facing the owner',
          content:
            'Through simple and efficient service plate, characteristic parking lot management, home-based elderly care, unified forum group of all owners of the project and other contents, the owner, property and business can have open, transparent and efficient activities and live without obstacles.',
        },
        mxsj: {
          title: 'Business oriented',
          content:
            'Through community group purchase, commodity details display, business information display and other contents, without any advertising, businesses can have zero distance contact with and face the owners, reduce operating costs and improve operating efficiency.',
        },
        mxdc: {
          title: 'Real estate oriented',
          content:
            'Integrate the developer, construction party, property management and owner into one inspection system, and clearly and in detail paperless inspection, repair, results and other processes, so as to make the seamless connection between the developer, construction party, property management and owner. Increase the quality of project delivery and building recovery rate, and improve project operation efficiency and owner satisfaction.',
        },
        mxzf: {
          title: 'Facing the government',
          content:
            'Through the overall integration of system data, the construction of four-level supervision service platform mode (owner side, industry committee side, enterprise side and supervision side), visual image processing, policy disclosure information, service window and other contents, so as to realize the sharing of information among property management enterprises, government departments and residents, enable government management and realize a better life in the community.',
        },
      },
    },
  },
  future: {
    wmdfz: {
      title: 'Our development',
      content: 'Came into being: the dual choice of the times and readers',
    },
    left: {
      title: 'economic performance',
      xtgg: {
        title: 'System advertisement',
        content:
          'There are 5 rolling advertisements on the home page, 1 recommendation advertisement on the next page, and several advertisements in the form of start-up page advertisement, window jumping advertisement, specific recommendation advertisement and so on.',
      },
      gwpx: {
        title: 'on-the-job training',
        content:
          'Provide on-the-job video training, which is charged annually; It can support enterprises to provide customized services and support designated training and teaching.',
      },
      zfgm: {
        title: 'Government purchase',
        content: 'Community Shop platform franchise fee.',
      },
      sqsp: {
        title: 'Community shops',
        content: 'Community Shop platform franchise fee.',
      },
      jzfw: {
        title: 'Housekeeping services',
        content:
          'Share in a company with accurate traffic promotion platform and enjoy household dividends......',
      },
      sqyl: {
        title: 'Community pension',
        content:
          'It can take shares in a community elderly care institution, provide customized functions and other supporting value-added services, and enjoy profit dividends.',
      },
      wyjm: {
        title: 'Property franchise',
        content: 'Franchise maintenance fee of property company.',
      },
    },
    right: {
      pd: {
        icon: 'Bedding',
        title: 'Development stage',
        content:
          'The development of the whole system will be completed on April 30, 2022;\nDevelop 1-3 property companies in 2022;\nIt will be operational on January 1, 2023.',
      },
      yl: {
        icon: 'profit',
        title: 'Developmental stage',
        content:
          'Product R & D, sales and maintenance of third line stability;\nRealize the use of more than 10 property companies.',
      },
      gs: {
        icon: 'high speed',
        title: 'Momentum stage',
        time: '2031—future',
        content:
          'The ability and competitiveness of product R & D, sales and maintenance have been steadily improved, and gradually serve the Beibu Gulf rim.',
      },
      ph: {
        icon: 'balance',
        title: 'Sales stage',
        content:
          "5-8 property companies officially used it throughout the year; \nThe company's external design business is gradually carried out; \n realize sales normalization; \n achieve balance of payments.",
      },
      qw: {
        icon: 'must',
        title: 'Ten million potential stage',
        content:
          'The products will be re optimized according to the market and strive to achieve an annual income of more than 10 million by 2030.',
      },
    },
  },
};
