<template>
	<div class="top-nav">
		<div>
			<img src="../../assets/logo.jpg" class="home-logo" @click="$router.push({name:'Home'})">
		</div>
		<div class="nav-list">
			<div class="nav-item" :class="Active==='Home'?'active-item':''" @click="$router.push({name:'Home'})">
				<div>{{ $t('homeTab') }}
				</div>
			</div>
			<div class="nav-item" :class="Active==='functionSelection'?'active-item':''" @click="$router.push({name:'functionSelection'})">
				<div>{{ $t('selectionTab') }}
				</div>
			</div>
			<div class="nav-item"
				:class="Active==='propertyExplore'||Active==='ownerExplore'||Active==='merchantExplore'?'active-item':''">
				<el-dropdown>
					<span class="el-dropdown-link">
						{{ $t('exploreTab') }}
						<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item
							@click.native="$router.push({name:'propertyExplore'})">{{ $t('exploreTab_property') }}</el-dropdown-item>
						<el-dropdown-item divided
							@click.native="$router.push({name:'ownerExplore'})">{{ $t('exploreTab_owner') }}</el-dropdown-item>
						<el-dropdown-item divided
							@click.native="$router.push({name:'merchantExplore'})">{{ $t('exploreTab_merchant') }}</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>

			<div class="nav-item" :class="Active==='Download'?'active-item':''"
				@click="$router.push({name:'Download'})">
				<div>
					{{ $t('downloadTab') }}
				</div>
			</div>
			<div class="lange">
				<el-dropdown>
					<span class="el-dropdown-link">
						{{ Language }}
						<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item @click.native="changeLange('zh','简体中文')">简体中文</el-dropdown-item>
						<el-dropdown-item divided @click.native="changeLange('en','English')">English</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HeaderNav',
		computed: {
			ShowNav: {
				get() {
					return this.$store.state.ShowNav
				}
			},
			Active: {
				get() {
					return this.$store.state.Active
				},
				set(val) {
					this.$store.commit('updateActive', val)
				}
			},
			Language: {
				get() {
					return this.$store.state.Language
				},
				set(val) {
					this.$store.commit('updateLanguage', val)
				}
			}
		},
		watch: {
			$route: 'routeHandle'
		},
		methods: {
			changeLange(lange, type) {
				localStorage.setItem('locale', lange)
				this.$i18n.locale = lange
				this.Language = type
			},
			routeHandle(route) {
				this.Active = route.name
			}
		},
	}
</script>

<style scoped lang="scss">
	.top-nav {
		display: flex;
		align-items: center;
		background-color: white;
		width: 100vw;
		height: 60px;
		top: 0;
		position: fixed;
		z-index: 999;
		box-shadow: 1px 3px 10px #ababab;

		.home-logo {
			width: 40px;
			height: 40px;
			cursor: pointer;
			margin-left: 20px
		}

		.nav-list {
			position: fixed;
			right: 20px;
			height: 50px;
			display: flex;

			.active-item {
				color: #148BE9;
			}

			.nav-item {
				font-size: 20px;
				height: 100%;
				width: 150px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				cursor: pointer;


				.el-dropdown {
					font-size: inherit;
					color: inherit;
				}

				&:hover {
					color: #148BE9;
				}

				.active-bottom {
					border-radius: 30px;
					background-color: #108EE9;
					width: 100%;
					height: 3px;
					margin-top: 20px;
				}
			}

			.lange {
				margin-left: 20px;
				width: 100px;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

	.el-dropdown-link {
		cursor: pointer;
	}

	.el-icon-arrow-down {
		font-size: 12px;
	}
</style>