import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { zh } from '@/i18n/config/zh';
import { en } from '@/i18n/config/en';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'zh',
  messages: {
    zh,
    en,
  },
});

export default i18n;
