import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ShowNav: false,
    Active: 'Home',
    Language: '简体中文'
  },
  mutations: {
    updateNavState (state, val) {
      state.ShowNav = val
    },
    updateActive (state, val) {
      state.Active = val
    },
    updateLanguage (state, val) {
      state.Language = val
    }
  },
  actions: {},
  modules: {}
})
