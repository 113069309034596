import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/Home'),
    meta: {
      title: '湛云（广东）物联科技有限公司',
    },
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('@/views/download/Download'),
    meta: {
      title: '下载',
    },
  },
  {
    path: '/propertyExplore',
    name: 'propertyExplore',
    component: () => import('@/views/explore/property/ExploreU'),
    meta: {
      title: '探索U+',
    },
  },
  {
    path: '/functionSelection',
    name: 'functionSelection',
    component: () => import('@/views/explore/function/Selection'),
    meta: {
      title: 'U+功能',
    },
  },
	{
	  path: '/ownerExplore',
	  name: 'ownerExplore',
	  component: () => import('@/views/explore/owner/ExploreU'),
	  meta: {
	    title: '探索U+',
	  },
	},
	{
	  path: '/merchantExplore',
	  name: 'merchantExplore',
	  component: () => import('@/views/explore/merchant/ExploreU'),
	  meta: {
	    title: '探索U+',
	  },
	},
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {
      title: '关于',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  window.scrollTo(0, 0);
  next();
});

export default router;
