<template>
  <div class="end-nav">
    <div>
      <div style="display: flex; margin-top: 50px">
        <div class="item">
          <div style="color: #000000">{{ $t("ontList.title") }}</div>
          <div class="nav">{{ $t("ontList.oneItem") }}: 0759-3330233</div>
          <div class="nav">{{ $t("ontList.twoItem") }}: 1091089949@qq.com</div>
        </div>
        <div class="item">
          <div style="color: #000000">{{ $t("twoList.title") }}</div>
          <div class="nav">DAPP</div>
          <div class="nav">{{ $t("twoList.twoItem") }}</div>
          <div class="nav">{{ $t("twoList.threeItem") }}</div>
          <div class="nav">{{ $t("twoList.fourItem") }}</div>
        </div>
        <div class="item">
          <div style="color: #000000">{{ $t("threeList.title") }}</div>
          <div class="nav">{{ $t("threeList.oneItem") }}</div>
          <div class="nav">{{ $t("threeList.twoItem") }}</div>
        </div>
      </div>
      <div style="display: flex; justify-content: center; margin: 10px 0">
        <!-- <img src="../../assets/wangjing.jpg" style="width: 20px;height: 20px;"> -->
        <a
          style="display: flex; justify-content: center"
          href="https://beian.miit.gov.cn"
          target="_blank"
          >粤ICP备2022037908号</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomNav",
};
</script>

<style scoped lang="scss">
.end-nav {
  background-color: #f1f1f1;
  //width: 100%;
  height: 280px;
  border-top: 1px solid #c4c4c4;
  display: flex;
  justify-content: center;

  .item {
    width: 250px;
    margin: 0 50px;
    color: #7c7c7c;

    .img-item {
      width: 50px;
      height: 50px;
      margin: 0 5px;
      cursor: default;
    }

    .nav {
      cursor: pointer;
      margin: 10px 0;
    }
  }
}
</style>
